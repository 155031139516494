import { useCallback, useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ELoanPurpose } from '@src/types'
import { ScrollableAlert } from '@src/components'
import { LoanPurposeInput, LoanPurposeInputSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  onPrequalificationUpdated: (data: LoanPurposeInput) => void
  prequalificationData: LoanPurposeInput
}

type LoanPurposeTileProps = {
  id: ELoanPurpose
  icon: string
  checked: boolean
  onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
}

const LoanPurposeTile = ({ id, icon, checked, onClick }: LoanPurposeTileProps) => {
  const { t } = useTranslation()
  return (
    <div className="radio-wrap">
      <input type="radio" value={id} id={id} checked={checked} name="loanPurpose" onClick={onClick} readOnly />
      <label htmlFor={id}>
        <i className={`fa-light fa-${icon}`} />
        <span>{t(`enum.eLoanPurpose.${id}`)}</span>
      </label>
    </div>
  )
}

const LoanPurposeForm = ({ prequalificationData, onPrequalificationUpdated }: Props) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<LoanPurposeInput>({
    mode: 'onBlur',
    resolver: yupResolver(LoanPurposeInputSchema),
    defaultValues: prequalificationData,
  })

  const bottomRef = useRef<HTMLDivElement | null>(null)

  const selectedLoanPurposeId = watch('loanPurposeId')
  const handleLoanPurposeSelected = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      setValue('loanPurposeId', event.currentTarget.value as ELoanPurpose)
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    },
    [setValue, bottomRef],
  )

  return (
    <section className="step-content">
      <h3 className="form-question">{t('loanPurpose.purpose')}</h3>

      <div className="paragraph">
        <p>{t('loanPurpose.sharePurpose')}</p>
      </div>

      <form onSubmit={handleSubmit(onPrequalificationUpdated)} id={FORM_ID}>
        <ScrollableAlert showAlert={!!errors.loanPurposeId} type="error" message={t('common.loanPurposeSelectError')} />
        <div className="control-group">
          <div className="radiobuttons-wrap with-icon">
            <LoanPurposeTile
              id={ELoanPurpose.Medical}
              icon="hospital"
              checked={selectedLoanPurposeId === ELoanPurpose.Medical}
              onClick={handleLoanPurposeSelected}
            />

            <LoanPurposeTile
              id={ELoanPurpose.Dentistry}
              icon="tooth"
              checked={selectedLoanPurposeId === ELoanPurpose.Dentistry}
              onClick={handleLoanPurposeSelected}
            />

            <LoanPurposeTile
              id={ELoanPurpose.Veterinary}
              icon="dog"
              checked={selectedLoanPurposeId === ELoanPurpose.Veterinary}
              onClick={handleLoanPurposeSelected}
            />

            <LoanPurposeTile
              id={ELoanPurpose.HomeImprovement}
              icon="house"
              checked={selectedLoanPurposeId === ELoanPurpose.HomeImprovement}
              onClick={handleLoanPurposeSelected}
            />

            <LoanPurposeTile
              id={ELoanPurpose.GoodsAndServices}
              icon="loveseat"
              checked={selectedLoanPurposeId === ELoanPurpose.GoodsAndServices}
              onClick={handleLoanPurposeSelected}
            />

            <LoanPurposeTile
              id={ELoanPurpose.Other}
              icon="envelope-open-dollar"
              checked={selectedLoanPurposeId === ELoanPurpose.Other}
              onClick={handleLoanPurposeSelected}
            />
          </div>
        </div>

        <div ref={bottomRef}> </div>
      </form>
    </section>
  )
}

export default LoanPurposeForm
