import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import { useGetFeedback } from '@src/api/feedback-api'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CustomerFeedbackSubmit from './CustomerFeedBackSubmit'

type Props = {
  creditApp: FilteredCreditApplication
}
const CustomerFeedbackTile = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [feedback] = useGetFeedback(creditApp.id)

  return !feedback ? (
    <CustomerFeedbackSubmit creditApp={creditApp} />
  ) : (
    creditApp.contract.status !== EContractStatus.Completed && (
      <main className="general-message">
        <h3>{t('feedback.thankYou')}</h3>
        <br />
        <div className="btn-group full-width-mobile">
          <button
            type="button"
            onClick={() => navigate(`/creditapplication/${creditApp.id}`)}
            className="btn btn-blue primary-action-btn"
          >
            <i className="fa-light fa-arrow-left" />
            <span>{t('common.backToApplication')}</span>
          </button>
        </div>
      </main>
    )
  )
}

export default React.memo(CustomerFeedbackTile)
