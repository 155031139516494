import { FilteredCreditApplication } from '@src/api/credit-api'
import { useTranslation } from 'react-i18next'

interface Props {
  creditApp: FilteredCreditApplication
}
const MonthlyPaymentEstimationNotice = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  return (
    <span style={{ fontSize: '1.5rem' }}>
      <sup style={{ color: 'red' }}>*</sup>
      {t('common.monthlyAmountsInclude', {
        financeFee:
          creditApp.merchantPaymentPlan !== null
            ? creditApp.merchantPaymentPlan.borrowerFeeRate
            : creditApp.normsDecision.lenderFeeRate,
        interestRate: creditApp.finalDecision.interestRate,
      })}
    </span>
  )
}

export default MonthlyPaymentEstimationNotice
