import {
  EApplicantType,
  ECreditApplicationStatus,
  EDecision,
  EDocumentStatus,
  EDocumentType,
  EExternalStep,
  ELoanPurpose,
  EPrequalificationDecision,
  EProvince,
  ERequiredExternalStepStatus,
  RequiredDocument,
  EConsignoSignatureStatus,
} from '@src/types'
import { FilteredWorksheet } from './FilteredWorksheet'

export type RequiredExternalStep = {
  id: string
  applicantType: EApplicantType
  externalServiceId: string
  externalStepId: EExternalStep
  status: ERequiredExternalStepStatus
  extraProperties: Record<string, string>
}

export type CreditApplicationDocument = {
  applicantType: EApplicantType
  status: EDocumentStatus
  typeId: EDocumentType
  refusalReason: string | null
  subKey: string | null
}

export type PrequalificationDecision = {
  decision: EPrequalificationDecision
  minInterestRate: number
  maxInterestRate: number
  maxLoanAmount: number
}
export type NormsDecision = {
  decision: string
  interestRate: number
  maxPmtAmount: number
  maxTermDuration: number
  maxAmountFinanced: number
  lenderFeeRate: number
}

export type FinalDecision = {
  decision: EDecision
  interestRate: number
  maxPmtAmount: number
  maxTermDuration: number
  maxAmountFinanced: number
}

export type MerchantPaymentPlan = {
  merchantFeeRate: number
  borrowerFeeRate: number
  reducedInterestRate: number
  interestRate: number
  reducedRateDurationInMonths: number
  loanTerm: number
}

export type FilteredApplicant = {
  firstName: string
  lastName: string
  middleName: string
  province: EProvince
  cellPhone: string
  homePhone: string
  age: number
  hasSin: boolean
  hasCreditReport: boolean
  hasLockedCreditReport: boolean
  isCreditTwoYearsOrLess: boolean
  areIncomesConfirmed: boolean
  areFlinksIncomeMatchingDeclared: boolean | null
  flinksHasEmploymentOrSocialInsuranceIncome: boolean | null
  canContinueWithComputedIncome: boolean | null
  hasSkippedIncomeValidation: boolean
  computedIncome: number | null
}

export enum EContractStatus {
  Ready = 'ready',
  Sending = 'sending',
  Declined = 'declined',
  NotReady = 'notready',
  UnderReview = 'review',
  Completed = 'completed',
}

export type Contract = {
  status: EContractStatus
  declinedReason: string | null
}

export enum EOriginSystemId {
  Backoffice = 'backoffice',
  MerchantDashboard = 'merchdb',
  Subscription = 'ifinancesub',
  B2c = 'b2csub',
  Autologiq = 'autologiq',
}

export type Signature = {
  status: EConsignoSignatureStatus
  isApplicant: boolean
  declinedReason: string
}

export type FilteredCreditApplication = {
  id: string
  referenceNumber: number
  status: ECreditApplicationStatus
  applicant: FilteredApplicant
  coapplicant: FilteredApplicant | null
  loanPurposeId: ELoanPurpose
  requiredDocuments: RequiredDocument[]
  documents: CreditApplicationDocument[]
  prequalificationDecision: PrequalificationDecision
  normsDecision: NormsDecision
  finalDecision: FinalDecision
  merchantPaymentPlan: MerchantPaymentPlan | null
  requestedLoanAmount: number
  worksheet: FilteredWorksheet | null
  contract: Contract
  merchantId: string
  merchantPaymentPlanId: string | null
  allIncomesConfirmed: boolean
  requiredExternalSteps: RequiredExternalStep[]
  completedVerifyAccountName: boolean
  loanCreated: boolean
  applicantWantsToCancel: boolean
  consentSoftHit: boolean
  consentHardHit: boolean
  updatedOn: Date
  cvtNumber: number | null
  originSystemId: EOriginSystemId
  expiresOn: Date
  hardHitReportReceivedOn: Date
  allTaskCompletedForCVT: boolean
  signers: Signature[]
}
